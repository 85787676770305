<template>
    <div class="timeSteps">
        <div v-for="(item,index) in list" class="item" :key="index" :style="{'--color':index<active+1?'#409eff':'#c2c2c6'}">
            <div :style="{color:index<active+1?'#409eff':'#5d5d5d'}" class="item_text">{{item.text}}</div>
            <div :style="{backgroundColor:index<active+1?'#409eff':'#c2c2c6'}" class="item_num">
                {{index+1}}
            </div>
            <div class="item_time">{{item.time}}</div>
        </div>
    </div>
</template>

<script>

export default {
    props:{
        list:{
            type:Array,
            default:()=>[]
        },
        active:{
            type:Number,
            default:0
        },
    },
}
</script>

<style lang="less" scoped>
    .timeSteps{
        padding: 30px 0;
        display: flex;
        justify-content: center;
    }
    .item{
        width: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        &+.item{
            margin-left: 10%;
            position: relative;
            &:before{
                content: "";
                position: absolute;
                top: calc(50% - 4px);
                right: 80%;
                left: -120%;
                background-color: var(--color);
                height: 4px;
            }
        }
        .item_text{
            margin-bottom: 15px;
        }
        .item_num{
            height: 32px;
            width: 32px;
            border-radius: 50%;
            color: #ffffff;
            text-align: center;
            line-height: 32px;
            font-size: 14px;
            margin-bottom: 20px;
        }
    }
</style>
