<template>
    <el-main v-if="info">
        <div class="title">预约信息</div>
        <div style="background:#F8F9FA;margin-top:20px;text-align:center;padding-bottom:30px;">
            <time-steps :list="time_list" :active="time_active"></time-steps>
            <div class="status">预约状态：{{status}}</div>
            <el-button type="primary" v-if="info.status == 10" @click="toshop">确认到店</el-button>
            <el-button type="primary" v-if="info.status == 20" @click="toCashier">开单收银</el-button>
            <el-button type="primary" v-if="info.status == 30" @click="toDiagnosisForm(0)">添加诊断</el-button>
            <el-button type="text" v-if="info.status == 40" @click="toDiagnosisForm(1)">查看诊断</el-button>
            <el-button type="text" v-if="info.status == 30 || info.status == 40" @click="toOrderDetail">订单详情</el-button>

        </div>
        <div class="title2">预约人信息</div>
        <el-table :data="tableData">
            <template slot="empty">
                <No />
            </template>
            <el-table-column label="预约用户" v-if="tableData[0].openid">
                <template v-slot="{ row }">
                    <div class="row">
                        <el-image :src="row.avatar" class="avatar"></el-image>
                        <div >{{row.nick_name}}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="name" label="下单人" align="center">
            </el-table-column>
            <el-table-column prop="mobile" label="预约电话" align="center">
            </el-table-column>
             <el-table-column prop="oprator_name" label="填写预约账号" align="center" v-if="tableData[0].oprator_name">
            </el-table-column>
            <el-table-column label="服务主图" align="center">
                <template v-slot="{ row }">
                     <el-image :src="row.service[0].images" class="images"></el-image>
                </template>
            </el-table-column>
            <el-table-column label="服务名称" align="center">
                <template v-slot="{ row }">{{row.service[0].service_name}}</template>
            </el-table-column>
            <el-table-column label="规格" align="center" v-if="tableData[0].service[0].service_spec_id">
                <template v-slot="{ row }">{{row.service[0].service_spec_name}}</template>
            </el-table-column>
            <el-table-column prop="staff.service_name" label="服务人员" align="center">
            </el-table-column>
            <el-table-column  label="支付方式" align="center">
                <template v-slot="{ row }">{{row.pay_type==1?'在线支付':'到店支付'}}</template>
            </el-table-column>
        </el-table>
        <div v-if="info.order_person_detail"  class="others">
            <div v-for="(item,index) in info.order_person_detail" :key="index">
                <span style="margin-right:40px;">预约人{{index+2}}：{{item.name}}</span>
                <span style="margin-right:120px;">联系电话：{{item.mobile}}</span>
            </div>
        </div>
    </el-main>
</template>

<script>
console.log(22222)
import timeSteps from '@/components/timeSteps';
import { getDateformat } from '@/util/getDate';  
export default {
    components:{
        timeSteps
    },
    data(){
        return {
            time_list:[],
            time_active:0,
            info:'',
            status:'',
            tableData:[]
        };
    },
    created() {
        this.getInfo()
    },
    methods: {
        getInfo(){
            this.$axios.post(this.$api.beauty.serveDetail, {id:this.$route.query.id}).then(res => {
                if (res.code == 0) {
                    this.info = res.result
                    if(this.info.status>0){
                        if(this.info.openid){
                            this.time_list = [{
                                text:'下单时间',
                                time:getDateformat(this.info.order_time)
                            },{
                                text:'预约到店时间',
                                time:getDateformat(this.info.order_time)
                            },{
                                text:'实际到店时间',
                                time:'--'
                            },{
                                text:'开单时间',
                                time:'--'
                            },{
                                text:'完成时间',
                                time:'--'
                            }]
                            if(this.info.realiy_time){
                                this.time_list[2].time = getDateformat(this.info.realiy_time)
                            }
                            if(this.info.open_time){
                                this.time_list[3].time = getDateformat(this.info.open_time)
                            }
                            if(this.info.finish_time){
                                this.time_list[4].time = getDateformat(this.info.finish_time)
                            }
                            let {status} = this.info
                            switch (status){
                                case 20:
                                    this.time_active = 2
                                    this.status = '已到店'
                                    break
                                case 30:
                                    this.time_active = 3
                                    this.status = '已开单'
                                    break
                                case 40:
                                    this.time_active = 4
                                    this.status = '已完成'
                                    break
                                default:
                                    this.time_active = 1
                                    this.status = '待到店'
                                    break
                            }
                        }else{
                            this.time_list = [{
                                text:'到店时间',
                                time:getDateformat(this.info.realiy_time)
                            },{
                                text:'开单时间',
                                time:'--'
                            },{
                                text:'完成时间',
                                time:'--'
                            }]
                            if(this.info.open_time){
                                this.time_list[1].time = getDateformat(this.info.open_time)
                            }
                            if(this.info.finish_time){
                                this.time_list[2].time = getDateformat(this.info.finish_time)
                            }
                            let {status} = this.info
                            switch (status){
                                case 30:
                                    this.time_active = 1
                                    this.status = '已开单'
                                    break
                                case 40:
                                    this.time_active = 2
                                    this.status = '已完成'
                                    break
                                default:
                                    this.time_active = 0
                                    this.status = '已到店'
                                    break
                            }
                        }
                    }else{
                         this.time_list = [{
                            text:'下单时间',
                            time:getDateformat(this.info.order_time)
                        },{
                            text:'预约到店时间',
                            time:getDateformat(this.info.order_time)
                        },{
                            text:'取消时间',
                            time:getDateformat(this.info.cancle_time)
                        }]
                        this.time_active = 2
                        this.status = '已取消'
                    }


                    let arr  = []
                    arr.push(this.info)
                    this.tableData = arr

                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        toshop(){
            this.$confirm('请确定用户是否到店?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
            .then(() => {
                this.$axios.post(this.$api.beauty.toshop, {id:this.info.id}).then(res => {
                    if (res.code == 0) {
                        this.$message.success('确认成功');
                        this.getInfo()
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            })
            .catch(() => {});
        },
        toCashier(){
            let routeData = this.$router.resolve('/reserve/cashier?id='+this.$route.query.id);
            window.open(routeData.href, '_blank');
        },
        toDiagnosisForm(is_look){
            this.$router.push({path:'/reserve/diagnosisAdd?id='+this.$route.query.id+'&is_look='+is_look})
        },
        toOrderDetail(){
            this.$router.push({path:'/order/serviceOrderInfo',query:{id:this.info.orders_id}})
        }
    },
};
</script>

<style lang="less" scoped>
.el-main {
  font-family: Microsoft YaHei;
  font-size: 14px;
  color: #17171a;
  background: #fff;
  padding: 30px 20px;
}
.title{
    width: 100%;
    padding-bottom:20px;
    border-bottom: 20px solid #e9eef1;
}
.status{
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    color: #2C3E50;
    margin-bottom: 30px;
}
.title2{
    padding: 30px 0 36px;
}
.row{
    display: flex;
    align-items: center;
}
.avatar{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}
.images{
    width: 88px;
    height: 88px;
}
.others{
    background: #F8F9FA;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 16px 30px;
    color: #2C3E50;
}
</style>
